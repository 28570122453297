#pricelistBox

  table.table

    thead
      text-transform: uppercase

      th
        border-bottom: 1px dashed #cccccc
        font-weight: bold

    tbody

      td
        vertical-align: top
